import * as Sentry from '@sentry/sveltekit';

Sentry.init({
    dsn: 'https://9c983022b34b49deaff1b497cdbd9bae@sentry.searchlight.vision/1',
    tracesSampleRate: 1.0,
    integrations: [Sentry.replayIntegration()],
    replaysOnErrorSampleRate: 1.0,
    enabled: import.meta.env.MODE === 'production',
});

export const handleError = Sentry.handleErrorWithSentry();
